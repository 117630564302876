/* Tela de Contratos da história PLV-4024 */
import React, {useState} from 'react';
import { Redirect } from 'react-dom';
import { FaDownload, FaFileAlt } from 'react-icons/fa'; // FNPVVEP-165 - INICIO/FIM
import axios from 'axios';

import './style.scss';

import DataGrid from '../../components/DataGrid';
// PLV-4254 - INICIO
import LocalSearch from '../../components/LocalSearch';
// PLV-4254 - FIM
// PLV-4259 - INICIO 
import Details from '../../components/Details';
import { useEffect } from 'react';
// MGRC-705 - INICIO
import getDocument, { openPdfInNewTab } from '../../util/pdf'; // MGRC-649 - INICIO/FIM
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';
// MGRC-705 - FIM
// PLV-4259 - FIM

export default function Entrada(){
    // PLV-4254 - INICIO
    const [searchParams, setSearchParams] = useState({});
    // PLV-4254 - FIM 
    // PLV-4259 - INICIO
    const [modalDetails, setModalDetails] = useState(false);
    const [detailsData, setDetailsData] = useState({});
    let dados = [];

    // PLV-4259 - FIM

    // MGRC-705 - INICIO
    const [showModalDownloadError, setShowModalDownloadError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    // MGRC-705 - FIM
    //FNPVVEP-33 INICIO
    //const [detailsPaymentData, setDetailsPayment] = useState({});

    const colunas = ['Apólice','Nome','CPF','Status','Prêmio / A Restituir','Documentos','Voucher','Ação']; // FNPVVEP-165 - INICIO/FIM
    const qtditens = 10;
    // PLV-4343 - INICIO
    /*const getDetailsPayment = async (value) => {
        return axios.post('/api/contractdetails', { 
            
                sucursal: value.sucursal,
                ramo: value.ramo,
                empresa: value.empresa,
                apolice: value.apolice.padStart(9,"0"),
                endosso: value.endosso,
                //portal: true
            
        })
        .then(r=>{
            let list = [];
            console.log('data', r.data);
            if(r.data && r.data.data){      
                const dados = r.data.data.map(x => x.payments);
                //const dados = r.data.data;
                setDetailsPayment(dados);
            }
            return [];
        });
    } */
    // PLV-4343 - FIM
    // PLV-4259 - INICIO
    const ofertaContrato = localStorage.getItem('codigoOferta')
    
    let fieldsDetails = {
        contract:{
            label: 'Contrato',
            fields: [
                {
                    label: 'Número da apólice',
                    value: 'policy',
                },
                {
                    label: 'Inicio de vigência',
                    value: 'inital_term',
                },
                {
                    label: 'Final de vigência',
                    value: 'final_term',
                },
                {
                    label: 'Número da proposta',
                    value: 'proposal_number',
                },
                {
                    label: 'Data de emissão',
                    value: 'emission_date',
                },
                {
                    label: 'Produto',
                    value: 'product',
                },
                {
                    label: 'Tipo',
                    value: 'policy_type'
                },
                {
                    label: 'Status',
                    value: 'status',
                },
                {
                    label: 'Garantias',
                    value: {
                        list: 'guarantees_list',
                        labels: ['Nome', 'Capital'],
                        fields: ['garantia', 'capital'],
                    }
                }
            ]
        },
        insured: {
            label: 'Segurado',
            fields: [
                {
                    label: 'Nome',
                    value: 'name',
                },
                {
                    label: 'Número de CPF',
                    value: 'identity_number',
                },
                {
                    label: 'Data de nascimento',
                    value: 'birth_date',
                },
                {
                    label: 'Sexo',
                    value: 'gender',
                },
                {
                    label: 'Profissão',
                    value: 'occupation',
                },
                {
                    label: 'Fumante',
                    value: 'smoker',
                },
                {
                    label: 'E-mail',
                    value: 'email',
                },
                {
                    label: 'Endereço',
                    value: {
                        list: 'address',
                        labels: ['CEP', 'Logradouro', 'Cidade', 'País', 'Estado'],
                        fields: ['zip_code', 'street', 'city', 'country', 'uf'],
                    }
                },
            ]
        },
        financial: {
            label: 'Financeiro',
            fields: [
                {
                    label: 'Forma de pagamento',
                    value: 'financial_responsible_payment_form',
                },
                {
                    label: 'Prêmio Total',
                    value: 'financial_responsible_reward',
                },
                {
                    label: 'Remuneração',
                    value: {
                        list: 'remuneration_list',
                        labels: ['Tipo', 'Percentual'],
                        fields: ['tipoRemuneracaoContrato','percentualContrato']
                    }
                },
            ]
        },
    }
    
    //FNPVVEP-33 FIM
    const detailsRender = {
        contract:{
            label: 'Contrato',
            fields: [
                {
                    label: 'Número da apólice',
                    value: 'policy',
                },
                {
                    label: 'Status',
                    value: 'status',
                },
                {
                    label: 'Inicio de vigência',
                    value: 'inital_term',
                },
                {
                    label: 'Final de vigência',
                    value: 'final_term',
                },
                {
                    label: 'Tem motocicleta?',
                    value: 'has_motorcycle',
                },
                {
                    label: 'Tipo de viagem',
                    value: 'travel_type',
                },
                {
                    label: 'Destino',
                    value: 'destiny',
                },
                {
                    label: 'Produto',
                    value: 'product',
                },
                {
                    label: 'Tipo',
                    value: 'policy_type'
                },
                {
                    label: 'Aventura?',
                    value: 'has_adventure',
                },
                {
                    label: 'Passageiros idosos',
                    value: 'old_passengers',
                },
                {
                    label: 'Passageiros novos',
                    value: 'young_passengers',
                },
                {
                    label: 'Número da proposta',
                    value: 'proposal_number',
                },
                {
                    label: 'Data de emissão',
                    value: 'emission_date',
                },
                {
                    label: 'Garantias',
                    value: {
                        list: 'guarantees_list',
                        labels: ['Nome', 'Capital'],
                        fields: ['garantia', 'capital'],
                    }
                }
            ]
        },
        insured: {
            label: 'Segurado',
            fields: [
                {
                    label: 'Nome',
                    value: 'name',
                },
                {
                    label: 'Número de CPF',
                    value: 'identity_number',
                },
                {
                    label: 'Data de nascimento',
                    value: 'birth_date',
                },
                {
                    label: 'Sexo',
                    value: 'gender',
                },
                {
                    label: 'Profissão',
                    value: 'occupation',
                },
                {
                    label: 'Fumante',
                    value: 'smoker',
                },
                {
                    label: 'E-mail',
                    value: 'email',
                },
                {
                    label: 'Endereço',
                    value: {
                        list: 'address',
                        labels: ['CEP', 'Logradouro', 'Cidade', 'País', 'Estado'],
                        fields: ['zip_code', 'street', 'city', 'country', 'uf'],
                    }
                },
            ]
        },
        financial: {
            label: 'Financeiro',
            fields: [
                {
                    label: 'Forma de pagamento',
                    value: 'financial_responsible_payment_form',
                },
                {
                    label: 'Prêmio Total',
                    value: 'financial_responsible_reward',
                },
                {
                    label: 'Remuneração',
                    value: {
                        list: 'remuneration_list',
                        labels: ['Tipo', 'Percentual'],
                        fields: ['tipoRemuneracaoContrato','percentualContrato']
                    }
                },
            ]
        },
    }

    const detailsPaymentRender = {
        payments:{
            label: 'Pagamentos',
            fields: [
                {
                    label: 'Parcelas',
                    value: {
                        list: 'parcel',
                        labels: ['Numero','Vencimento','Valor','Status'],
                        fields:['numeroparcela','datavencimento','valorpremiomensal','status'],
                    }
                },
            ]
        }
	}

    const showModal = () => {
        setModalDetails(true);
    }

    const hideModal = () => {
        setModalDetails(false);
        console.log('modalDetails', modalDetails)
    }

    const showDetails = (event) => {
        let contrato = dados.find(e => e.policy === parseInt(event.target.dataset.numeroapolice));
        //FNPVVEP-33 INICIO
        //getDetailsPayment(value); 
        //FNPVVEP-33 FIM
        /// PLV-4343 - INICIO
        //getDetailsPayment(event.target.dataset.numeroapolice); 
        /// PLV-4343 - FIM   
        if(ofertaContrato !== 'VIDA_INDIVIDUAL'){
            fieldsDetails = detailsRender
        }
        setDetailsData(contrato);
        showModal();
        console.log('event ' + JSON.stringify(event.target.dataset)); //PLV-5554LOG
    }
    // PLV-4259 - FIM

    // MGRC-705 - INICIO
    const handleDownloadPdf = async (token) => {
        setShowSpinner(true);
        const pdfDocument = await getDocument(token);
        setShowSpinner(false);
        if (!!pdfDocument) {
            openPdfInNewTab(pdfDocument);
        }
        else {
            setShowModalDownloadError(true);
        }
    }
    // MGRC-705 - FIM

    const getData = () => {
        // Dados para a request:
        // SUSEP
        let motivoErro = '';

        // "Contornando" aquele problema de que a pesquisa por apólice obriga a vir empresa, mas o corretor não sabe
        // PLV-4254 - INICIO
        // if(searchParams.numeroApolice){
        //     searchParams.numeroApolice = searchParams.numeroApolice; 
        // } //PLV-5554
    
        document.querySelector('.modalLoaderErro').classList.remove('off');
        document.querySelector('.pagina').classList.remove('on');

         // PLV-4254 - FIM
        return axios.get('/api/contract', {
                params: {
                    susep: localStorage.getItem('susepCorretor'),
                    codigoOferta: localStorage.getItem('codigoOferta'),  //II-124 - INICIO/FIM
                    // PLV-4254 - INICIO
                    ...searchParams
                    // PLV-4254 - FIM
                }
            }).then(r=>{
                if(r.status === 200){
                    if(r.data && r.data.data){
                        let dadosGrid = r.data.data.map((l)=>{
                            // FNPVVEP-165 - INICIO
                            const downloadUrl =
                              l.policy_url || l.endosso_url || '';
                            const documentosButton = !!downloadUrl ? (
                              <button
                                className="btn"
                                style={{ minWidth: 100 }}
                                onClick={() => handleDownloadPdf(downloadUrl)}
                              >
                                <FaDownload size={10} />{' '}
                                {!!l.policy_url ? 'Apólice' : 'Endosso'}
                              </button>
                            ) : <p style={{ marginLeft: 25 }}>------------</p>;
                            // FNPVVEP-165 - FIM
                            return [
                                // PLV-4259 - INICIO | PLV - PLV 5554 - FIX01 - INICIO 
                                <span onClick={showDetails} className="open-details" data-empresa={l.company_code} data-sucursal={l.branch_office} data-ramo={l.branch_code} data-numeroapolice={l.policy} data-endosso={l.endorsement_code}>
                                    { `${l.branch_office}.${l.branch_code}.${(l.policy + "").padStart(9,"0")}.${l.endorsement_code}` } 
                                </span>,
                                // PLV-4259 - FIM | PLV - PLV 5554 - FIX01 - FIM
                                l.client.split(" - ")[0],
                                l.client.split(" - ")[1],
                                l.status,
                                l.reward,
                                // MGRC-649 - INICIO
                                documentosButton, // FNPVVEP-165 - INICIO/FIM
                                l.voucher_url !== null ?
                                    <button className="btn" onClick={() => handleDownloadPdf(l.token_voucher)}><FaDownload size={10}/> Voucher</button> : "", // MGRC-705 - INICIO/FIM
                                // MGRC-649 - FIM
                                l.status === 'Ativo' ? <button className="btn hidden" onClick={() => {}}><FaFileAlt size={10}/> Gerar Endosso</button> : <p style={{ marginLeft: 40 }}>------------</p>, // FNPVVEP-165 - INICIO/FIM
                            ]; 
                        });

                        // PLV-4259 - INICIO
                        dados = [...r.data.data];
                        // PLV-4259 - FIM

                        return dadosGrid;
                    }
                    return [];
                }else{
                    throw new Error({name:"teste", message: r.response.data.result.userErrorDescription});
                }
            }).catch(e=>{
                /* PLV-4259 - INICIO */
                return <Redirect from="/contratos" to={{
                    pathname: "/error",
                    search: ("?erro=" + ((motivoErro !== ''? motivoErro: false) || 'Erro na integração com o serviço de contratos')),
                }} />;
                /* PLV-4259 - FIM */
            });
    };

    /* PLV-4254 - INICIO */
    const search = {
        // PLV 4351 - Felipe - INICIO 
        nome: {
            label: 'Nome',
            module: [
                { param: 'nomeSegurado', label: 'Nome', type: 'text' },
            ],
        },
        // PLV 4351 - Felipe - FINAL 
        cpf: {
            label: 'CPF',
            module: [
                { param: 'cpfContratante', label: 'CPF', type: 'cpf', placeholder:'999.999.999-99' }
            ]
        },
        apolice: {
            label: 'Apólice',
            module: [
                { param: 'numeroApolice', label: 'Apólice', type: 'apolice', placeholder:'9999-9999-999999999-9' } //PLV-5554 INICIO/FIM 
            ]
        },
        /* PLV-4351 - FELIPE - INICIO */
        status: {
            label: 'Status',
            module: [
                { param: 'statusApolice', label: 'Status', type: 'select', values: [
                    { id: 'emitido', label: 'Ativo'},
                    { id: 'cancelado', label: 'Cancelado'}
                ] }
            ]
        }
        /* PLV-4351 - FELIPE - FINAL */
    }
    /* PLV-4254 - FIM */
     
    return(
        <div id="contratos"> 
            <section className='modalLoaderErro off'>
                <div className='content'>
                    <div className="loader"></div>
                </div>
            </section>
             {/* PLV-4254 - INICIO */}
            <div className="pagina on">
                <LocalSearch fields={search} setSearch={setSearchParams} />
                {
                    Object.keys(searchParams).length === 0 ? 
                            "" : 
                            <>
                                <DataGrid cols={colunas} search={searchParams} getData={getData} qtditens={qtditens}/>
                                <span className="warning">* Neste Portal apresentamos os status atuais das apólices. </span>  
                            </>
                }         
            </div>
            {/*FNPVVEP-33 INICIO*/}
            <Details title="da apólice" show={ modalDetails } fields={ fieldsDetails } data={ detailsData } fields2={ detailsPaymentRender } data2={ detailsData } close={ hideModal }>
            </Details>
            {/*FNPVVEP-33 FIM*/}
            {/* MGRC-705 - INICIO */}
            {showModalDownloadError && <Modal title="Erro no download" onClose={() => setShowModalDownloadError(false)}>Não foi possível retornar o documento, por gentileza aguarde alguns minutos e tente novamente.</Modal>}
            {showSpinner && <Spinner />}
            {/* MGRC-705 - FIM */}
        </div>
    );
}